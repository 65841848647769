import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

@keyframes animation {
    from {
      background-color: #1a293d;
    }
    to {
      background-color: #00AEFF;
    }
  }


body {

    font-size: 2vmin;
    color: #fff;
    background-color: #fafafa;
    width: 100vw;
    height: 100vh;
    font-family: 'Encode Sans', sans-serif;

    .container {
        width: 100vw;
        height: 100vh;
        display: flex;
      justify-content: center;
      align-items: center;
    }

    .cards-container {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        width: 95vw;
        height: 78vh;
        gap: 0.1vmin;
        /* & > * {
            margin: 1px;
        } */
    }
    
    .card {
        width: 30vw;
        height: 38vh;
        padding: 2vmin;
        background-color: #00AEFF;
        animation: animation 30s !important;
        /* &::before {
            content: "";
            width: 20vw;
        height: 28vh;
        box-shadow: inset -2px 3px 23px -10px  rgba(0,0,0,0.1),  -5px 12px 15px -5px rgba(0,0,0,0.3);
        } */
     
        /* box-shadow: inset -2px 3px 23px -10px  rgba(0,0,0,0.2),  1px 9px 26px -8px rgba(0,0,0,0.6); */
        box-shadow: rgba(0, 0, 0, 0.40) 0px 5px 15px;
        &-patient {
            width: 90%;
            font-size: 6vmin;
            font-weight: 100;
            padding: 10px;
            //background-color: #068AF3;
            background-image: linear-gradient(130deg,  #0c68e8 0%, rgba(255,0,0,0) 70%);
        }

        &-bottom {
            font-size: 4.8vmin;
            margin-top: 1.5vmin;
            font-weight: 300;
            & > *:not(:last-child) {
                margin-bottom:0.5vmin;
            }
        }
    }
}
`;
