import { useState, useEffect } from 'react';
import moment from 'moment';

const Clock = (props) => {
  const [time, setTime] = useState(moment().format('HH:mm'));

  useEffect(() => {
    setInterval(() => setTime(moment().format('HH:mm')), 1000);
  }, []);

  return (
    <div {...props} className={'clock'}>
      {time}
    </div>
  );
};

export default Clock;
