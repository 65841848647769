import { useState, useEffect } from 'react';
import { Carousel, Message } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
//import StyledContainer from './Container';
// import axios from 'axios';
import styled from 'styled-components';
import axios from 'axios';
// import useSound from 'use-sound';
// import logo from './assets/logo.jpg';
// import img1 from './assets/1.jpg';
// import img2 from './assets/2.jpg';
// import img3 from './assets/3.jpg';
// import img4 from './assets/4.jpg';
//import logo2 from './assets/logo2.png';

// import img1 from './assets/1.jpg';
// import img2 from './assets/3.jpg';
// import img3 from './assets/7.jpg';
// import img4 from './assets/8.jpg';
// import img5 from './assets/17.jpg';
// import img6 from './assets/21.jpg';
// import img7 from './assets/22.jpg';
// import img8 from './assets/23.jpg';
// import img10 from './assets/30.jpg';
// import img12 from './assets/newInfoLab.jpg';
// import img11 from './assets/32.png';

// import img13 from './assets/2.jpeg';
// import song from './assets/song.mp3';
import Clock from './Clock';

import useWebSocket from 'react-use-websocket';

const StyledHeader = styled.header`
  position: fixed;
  z-index: 1000;
  top: 2vmin;
  right: 4vmin;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: #1e2d40;
  font-size: 3vmin;
  font-weight: 400;
  .clock {
    font-size: 3.5vmin;
    margin-top: -1.8vmin;
  }

  /* padding: 5px;
  border-radius: 10px;
  background-color: white;
  img {
    max-width: 13vw;
  } */
`;

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 2vmin;
  right: 4vmin;
  font-size: 1.5vmin;
  /* padding: 20px; */
  /* background-color: #1fa6e9; */
  /* max-width: 4vw;
  max-height: 4vw; */
  color: #1a293d;
  span {
    font-weight: 700;
  }
  /* display: flex;
  justify-content: center;
  align-items: center; */
  img {
    max-width: 3vw;
  }
`;
// const URLimages = window.location.href;
//const regex = /(http|https)/;
//const URL = `wss://${window.location.href.replace(regex)}/telebim`;
/* eslint-disable */
// const URL = 'wss:///dev.docker.lan:8080/telebim'; // -> production
// const URL = 'wss:///192.168.0.61:8080/telebim'; // => PRODUCTION MEDART
// const URL = 'wss:///192.168.0.61:8080/telebim'; // => PRODUCTION MEDART
// const URL = 'wss://prodapi.medart.pl/telebim'; // => PRODUCTION MEDART
// const URL = 'wss://uatapi.medart.pl/telebim'; // => test MEDART
const wsProtocol = window.location.protocol === 'http:' ? 'wss:' : 'wss:';
const URL = `${wsProtocol}//${window.location.host}/ws/v1/telebim`; // => test MEDART
// const URL = 'wss://uatapi.medart.pl/api/v1/telebim'; // => test MEDART

const findByName = (data, name) => {
  if (data.find((el) => el?.name === name) !== undefined) {
    return data.find((el) => el.name === name);
  }
  return null;
};

function App() {
  const [summoners, setSummoners] = useState([]);
  const [imagesUrlArr, setImagesUrlArr] = useState([]);
  // const [play] = useSound(song, { interrupt: true });
  // const [listImages, setListImages] = useState(null);
  const [error, setError] = useState(null);
  const [songAPI, setSongAPI] = useState('');
  const [companyName, setCompanyName] = useState('QVX TV');
  const [companyNameErr, setCompanyNameErr] = useState(false);

  // const contentProps = useSpring({
  //   to: { opacity: 1, marginTop: 0 },
  //   from: { opacity: 1, marginTop: -1000 }
  // });

  const { lastMessage } = useWebSocket(URL, {
    onOpen: () => console.log('WebSocket connection opened.'),
    onClose: () => {
      console.log('WebSocket connection closed.'), setSummoners([]);
    },
    shouldReconnect: () => true
  });
  // useEffect(() => {
  //   axios
  //     .get(`${URLimages}list`)
  //     .then((res) => {
  //       setError(null);
  //       setListImages(res.data);
  //       console.log(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const reloadAppName = () => {
    setCompanyNameErr(false);
    axios
      .get(`${window.location.protocol}//${window.location.host}/api/v1/config`, {
        params: {
          name: 'prodName'
        }
      })
      .then((res) => {
        const findedProductByName = findByName(res.data.data, 'prodName');
        setCompanyName(findedProductByName.value);
        setCompanyNameErr(false);
      })
      .catch((err) => {
        console.log(err);
        setCompanyNameErr(true);
        setCompanyName('QVX TV');
      });
  };

  useEffect(() => {
    reloadAppName();

    // .then((res) => {
    //   const findedProductByName = findByName(res.data.data, 'prodName');
    //   console.log('tuuu');
    //   console.log(findedProductByName);
    //   console.log(res.data);
    //   console.log('taaam');
    //   if (findedProductByName) {
    //     setCompanyName(findedProductByName.value);
    //   }
    //   // setCompanyName();
    // })
    // .catch((err) => {
    //   setCompanyName('QVX TV');
    // });
  }, [companyNameErr]);

  useEffect(() => {
    if (summoners.length > 0) {
      const audio = new Audio(songAPI);
      console.log(audio);
      audio.play();
      // play();
    }
    // }, [summoners, play]);
    console.log(summoners);
  }, [summoners]);

  useEffect(() => {
    console.log(songAPI);
  }, [songAPI]);

  useEffect(() => {
    console.log(imagesUrlArr);
  }, [imagesUrlArr]);

  useEffect(() => {
    console.log(`${window.location.protocol}//${window.location.host}`);
    console.log();
    if (companyNameErr) {
      // reloadAppName();
    }
    if (lastMessage !== null) {
      console.log(JSON.parse(lastMessage.data));
      try {
        setSongAPI(`${JSON.parse(lastMessage.data).sound}`);
      } catch {
        setSongAPI();
      }
      stop();
      try {
        setImagesUrlArr([...JSON.parse(lastMessage.data).links]);
      } catch {
        setImagesUrlArr([]);
      }
      try {
        setSummoners(JSON.parse(lastMessage.data).summon);
        setError(null);
      } catch {
        setSummoners([]);
        setError('Coś poszło nie tak.');
      }
    } else {
      setSummoners([]);
      setError(null);
    }
  }, [lastMessage, setSummoners]);

  return (
    <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
      <StyledHeader>
        <div>{companyName}</div>
        <Clock />
      </StyledHeader>
      {error ? (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Message showIcon type="error" header="Error">
            {error}
          </Message>
        </div>
      ) : (
        <>
          {summoners?.length > 0 ? (
            <div className={'container'}>
              <div className="cards-container">
                {summoners.reverse().map((summon) => (
                  <div className="card" key={summon.id}>
                    <div>
                      <div>
                        <p className={'card-patient'} key={`${summon.patients[0]}-summon.id`}>
                          {summon.patients[0]}
                        </p>

                        {/* {summon.patients.map((p) => (
                          <p className={'card-patient'} key={`${p}-summon.id`}>
                            {p}
                          </p>
                        ))} */}
                      </div>
                      <div className={'card-bottom'}>
                        <div>
                          {summon.doctors.map((d) => (
                            <p key={`${d}-summon.id`}>
                              {d}
                              {summon.doctors.length > 1 ? ', ' : null}
                            </p>
                          ))}
                        </div>

                        <p>{summon.room}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Carousel
              autoplayInterval={40000}
              style={{ height: '100vh', zIndex: 500 }}
              autoplay
              className="custom-slider">
              {imagesUrlArr.map((url) => (
                <img src={`${url}`} key={url} height="90vh" />
              ))}
              {/* <img
                src={`${window.location.protocol}//${window.location.host}/api/v1/config/file/1000046`}
                height="90vh"
              /> */}
              {/* <img src={img1} height="90vh" />
              <img src={img2} height="90vh" />
              <img src={img3} height="90vh" />
              <img src={img4} height="90vh" />
              <img src={img5} height="90vh" />
              <img src={img6} height="90vh" />
              <img src={img7} height="90vh" />
              <img src={img8} height="90vh" />
              <img src={img10} height="90vh" />
              <img src={img11} height="90vh" />
              <img src={img12} height="90vh" /> */}

              {/* <img src={img13} height="90vh" /> */}

              {/* {listImages ? (
                listImages.map((img) => (
                  <img key={img} src={`${URLimages}images/${img}`} height="90vh" />
                ))
              ) : (
                <Loader center />
              )} */}
            </Carousel>
          )}
        </>
      )}

      <StyledFooter>
        Aplikacja stworzona przez <span>QVX</span>
      </StyledFooter>
    </div>
  );
}

export default App;
